<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>Application Received Report</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class='pl-4'>
                <v-col md="3">
                    <label>Academic Year &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="ay_list" item-text="ay" item-value="id" v-model="ay"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Placement Type  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="placementtype_list" item-text="name" item-value="id" v-model="placementtype"></v-autocomplete>
                </v-col>
                <!-- <v-col md="3">
                    <label>Company Offering  &nbsp;<small style="color:red;">*</small></label> -->
                    <!-- <v-autocomplete autocomplete="off"  chips multiple :items="offeringlist" item-text="name" item-value="id" v-model="offering"></v-autocomplete> -->
                
                    <!-- <v-combobox
                        item-text="name"
                        v-model="offering"
                        :items="offeringlist"
                        multiple="true"
                        item-value="id"                                                           
                        dense
                        clearable
                    ></v-combobox>
                </v-col> -->
            
                <v-col md="3">
                    <label>Organization  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete :items="institute_list" item-text="name" item-value="id" v-model="institute" @change="getProgramType()"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Program Type &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="programtype_list" item-text="name" item-value="id" v-model="programtype" @change="getProgram()"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <label>Program  &nbsp;<small style="color:red;">*</small></label>
                    <!-- <v-autocomplete autocomplete="off"  chips multiple="true" :items="program_list" item-text="name" item-value="id" v-model="program"></v-autocomplete> -->
                    <v-combobox
                        item-text="name"
                        v-model="program"
                        :items="program_list"
                        multiple="true"
                        item-value="id"                                                           
                        dense
                        clearable
                    ></v-combobox>
                
                </v-col>
            
                <v-col md="3">
                <label>Application Count &nbsp;<small style="color:red;">*</small></label>
                    <v-text-field
                        v-model="applicationcount"
                        type="number"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col md="3">
                    <v-checkbox dense v-model="isgreater" color="success" label="Is Greater than application count"></v-checkbox>
                </v-col>
                <v-col sm="3">
                    <v-btn class="mt-6" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div v-if="finalList.length != 0">
            <!-- <v-row justify="end" class="mr-5">
                <v-btn v-if="finalList.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
           
            <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="finalList.length!=0">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left"> Sr.No. </th>
                            <th class="text-left"> GR Number </th>
                            <th class="text-left"> Name</th>
                            <th class="text-left"> Organization</th>
                            <th class="text-left"> Program</th>
                            <th class="text-left"> Year</th>
                            <th class="text-left"> Institute Email</th>
                            <th class="text-left" >Contact</th>
                            <th class="text-left"> Application Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in finalList" :key="item.srno">
                            <td>{{ item.srno }}</td>
                            <td>{{ item.grno }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.org }}</td>
                            <td>{{ item.program }}</td>
                            <td>{{ item.year }}</td>
                            <td>{{ item.institute_email }}</td>
                            <td>{{ item.contact }}</td>
                            <td>{{ item.count }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table> -->

             <v-data-table
        :headers="headers"
        :items="finalList"
        class="elevation-1"
          :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Application Received List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
         
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <download-excel
              class="mar"
              v-if="finalList.length != 0"
              color="primary"
              dark
              :data="finalList"
              :fields="table_fields"
              worksheet="My Worksheet"
              name="Application Recevied Report.xls"
            >
              <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>
            <span style="width: 20px"></span>
          </v-toolbar>
        </template>
      </v-data-table>
        </div>
        <v-overlay :value="overlay">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
       // offeringlist:[],offering:null,
        finalList: [],
        yearmasterlist: [],
        yeartotal_list:[],
        ay_list: [],
        institute_list: [],
        programtype_list: [],
        program_list: [],
        placementtype_list : [],
        applicationcount:null,
        ay: null,
        institute: null,
        programtype: null,
        program: null,
        placementtype:null,
        isgreater:true,
        ayid : null,
        placementtypeid:null,
        progid:null,
        ptid:null,
        orgid:null,
        yearfinaltotal : 0,


         headers: [
        {
          text: "Sr.NO",
           sortable: true,
          value: "srno",
        },
        { text: "GR Numbner", sortable: true, value: "grno" },
        { text: "Name",  sortable: true,value: "name" },
        { text: "Organization",  sortable: true,value: "org" },
        { text: "Program",  sortable: true,value: "program" },
        { text: "Year", sortable: true, value: "year" },
        { text: "Institute Email",  sortable: true,value: "institute_email" },
         { text: "Contact",  sortable: true,value: "contact" },
         { text: "Count", sortable: true, value: "count" },
      ],
      table_fields: {
      "Sr.NO": "srno",
      "GR Numbner": "grno",
      Organization: "org",
      Program: "program",
      Year: "year",
      "Institute Email": "institute_email",
       Contact:"contact",
       Count:"count",
      
    },

    }),

    mounted() {
        axios
            .post("/TPO/applicationreportfilter")
            .then(res => {
                if (res.data.msg == "200") {
                    this.ay_list = res.data.ay_list;
                    this.ay = res.data.ay;
                    this.institute_list = res.data.institute_list;
                    this.program_list = res.data.program_list;
                    this.programtype_list = res.data.programtype_list;
                    this.placementtype_list = res.data.placementtype_list;

                    if(this.institute_list.length > 1){
                        this.institute="All";
                        this.institute_list.push({name: 'All', text: ''});
                    }else if(this.institute_list.length > 0){
                         this.institute= this.institute_list[0].id;
                    }
                    if(this.program_list.length > 1){
                        this.program_list.push({name: 'All', id: 'null'});
                    }else if(this.program_list.length > 0){
                         this.program= this.program_list[0].id;
                    }
                    if(this.programtype_list.length > 1){
                        this.programtype="All";
                        this.programtype_list.push({name: 'All', text: ''});
                    }else if(this.programtype_list.length > 0){
                         this.programtype= this.programtype_list[0].id;
                    }
                     if(this.placementtype_list.length > 1){
                        this.placementtype = "All";
                        this.placementtype_list.push({name: 'All', text: ''});
                    }else if(this.placementtype_list.length > 0){
                       this.placementtype = this.placementtype_list[0].id;
                    }

                } else {
                    //console.log("error fetching data!");
                }
            });
    },

    methods: {
        exportexcel() {    
            $("#exceltable").table2excel({  
            name: "Worksheet Name",
            filename: "Application Report", //do not include extension
            fileext: ".xls" // file extension
            });        
        },

        fetchReport() { 
            
            if(this.program == null){
                this.showSnackbar("red", "Please select Program");
            }
            else if(this.applicationcount == null){
                this.showSnackbar("red", "Please enter application count");
            }else{
                this.overlay = true;
            const data = {
                instituteid: this.institute,
                programtypeid:this.programtype,
                programid:this.program,
                ay:this.ay,
                placementtype:this.placementtype,
                offering:this.offering,
                applicationcount: this.applicationcount,
                isgreater:this.isgreater,
            };
            axios
                .post("TPO/fetchapplicationreport",data)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {
                        this.finalList = res.data.finalList;
                        if(this.finalList.length < 1){
                            this.showSnackbar("red", "Data Not Found");
                        }
                    } else {
                        this.showSnackbar("red", res.data.error);
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
            }
        },
        
        getProgramType(){
            const data = {
                instituteid: this.institute,
            };
            axios
            .post("TPO/getProgramTypeByOrganization", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.programtype_list = res.data.programtype_list;
                    if(this.programtype_list.length > 1){
                        this.programtype="All";
                        this.programtype_list.push({name: 'All', text: ''});
                    }else if(this.programtype_list.length > 0){
                         this.programtype= this.programtype_list[0].id;
                    }
                    getProgram();
                } else {
                    this.showSnackbar("green", res.data.msg1);
                }
            }).catch((error) => {
                window.console.log(error);
            }).finally(() => {});
        },

        getProgram(){
            const data = {
                instituteid: this.institute,
                programtypeid:this.programtype,
            };
            axios
            .post("TPO/getProgramsByOrganizationAndProgranType", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.program_list = res.data.program_list;
                    if(this.program_list.length > 1){
                      //  this.program="All";
                        this.program_list.push({name: 'All', id: 'null'});
                    }else if(this.program_list.length > 0){
                         this.program= this.program_list[0].id;
                    }
                } else {
                    this.showSnackbar("green", res.data.msg1);
                }
            }).catch((error) => {
                 window.console.log(error);
            }).finally(() => {});
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        
        clear() {
            this.excelfile = null;
            this.exceldialog = false;
        },
        
        closeerrordialog() {
            this.clear();
            this.excelerrordialog = false;
        },
    }
};
</script>
<style scoped>
    .required{
        color:red;
        font-size:14px;
        font-style:bold;
    }

    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
</style>    